import {
  CircularProgress,
  InputAdornment,
  useMediaQuery,
  IconButton,
  Alert,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import FormControlLabel from "@mui/material/FormControlLabel";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link as NavigateLink } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { useTheme } from "@mui/styles";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import localforage from "localforage";
import format from "date-fns/format";
import Box from "@mui/material/Box";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";

import {
  setTransactionsOverlayStatus,
  setPopupStatus2,
  setPopupStatus3,
  setPopupStatus4,
  setPopupStatus,
} from "../../../store/slices/datasets";
import {
  setIsAccountSelectOverlayOpen,
  setIsFirstLoginOverlayOpen,
  setJoyRideStatus,
} from "../../../store/slices/global";
import {
  setIsFatching,
  setIsLoading,
  setLoading,
} from "../../../store/slices/appmain";
import {
  getDecryptedItem,
  setEncryptedItem,
  UserLogin,
} from "../../../Helper/Storage";
import {
  removeAllCookies,
  updateLanguage,
  removeCache,
} from "../../../Helper/data";
import { setProfile, setAddFrom } from "../../../store/slices/settings";
import { setIsAllHeaderApiFetched } from "../../../store/slices/common";
import { Color, Fonts, Images } from "../../../Helper";
import authSlice from "../../../store/slices/auth";
import EndPoints from "../../../APICall/EndPoints";
import initialData from "./../../../Helper/data";
import OtpVerification from "./OtpVerification";
import { queryClient } from "../../../App";
import APICall from "../../../APICall";
import LeftView from "./LeftView";

const inputStyle = {
  "& .MuiInputBase-root": {
    fontSize: "1rem",
  },
  "& .MuiFormLabel-root": {
    fontSize: "1rem",
  },
};
const errorStyle = {
  color: "error.main",
  fontSize: "1rem",
};

export default function LoginScreen() {
  return (
    <Grid container component="main" sx={{ height: "100%" }}>
      <RightView />
      <LeftView />
    </Grid>
  );
}

const RightView = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const mdSize = useMediaQuery("(max-width:1745px)");
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values) => {
      handleLogin(values.email, values.password);
    },
    validationSchema: Yup.object({
      email: Yup.string().trim().required("Email address is required"),
      password: Yup.string().trim().required("The password is required"),
    }),
  });

  //state
  const [values, setValues] = useState({
    showPassword: false,
  });
  const [isRememberMe, setIsRememberMe] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [is_readyLoader, setIs_readyLoader] = useState(false);
  const [isOtp, setIsOtp] = useState(false);

  useEffect(() => {
    let storedStatus = getDecryptedItem(UserLogin, UserLogin);
    if (storedStatus) {
      formik.setFieldValue("email", storedStatus?.email);
      formik.setFieldValue("password", storedStatus?.password);
    }
    formik.setFieldError("email", null);
    formik.setFieldError("password", null);

    clearAllState();
  }, []);

  //api
  const getProfile = async (res) => {
    await APICall("get", EndPoints.profile).then(async (response) => {
      if (response.status === 200 && response.data) {
        let data = response.data;
        if (data?.is_ready) {
          localStorage.setItem("refreshToken", res?.data.refresh);
          queryClient.removeQueries();
          setIs_readyLoader(false);
          setBtnLoader(false);
          dispatch(setProfile(data));
          dispatch(
            authSlice.actions.setAuthTokens({
              token: res.data.access,
              refreshToken: res.data.refresh,
            })
          );
          dispatch(
            authSlice.actions.setLoginTime({
              loginTime: new Date(),
            })
          );
          dispatch(authSlice.actions.setAccount(res.data.user));
          dispatch(setJoyRideStatus(null));
          dispatch(setAddFrom(null));

          updateLanguage(response.data?.locale);
          await getDataSetsApi();
        } else {
          setIs_readyLoader(true);
          setBtnLoader(false);
          setTimeout(() => {
            getProfile(res);
          }, 3000);
        }
      } else {
        clearAllState();
      }
    });
  };

  const getDataSetsApi = async () => {
    await APICall("get", EndPoints.datasets).then((response) => {
      if (response.status === 200 && response.data) {
        if (response.data.results?.length === 1) {
          if (response?.data?.results?.[0]?.uuid) {
            navigate(
              `/${initialData?.path?.organization}/${response?.data?.results?.[0]?.uuid}/table`,
              {
                replace: true,
                state: { fromLogin: true },
              }
            );
          } else {
            navigate("/dashboard", {
              replace: true,
              state: { fromLogin: true },
            });
          }
        } else {
          navigate("/dashboard", {
            replace: true,
            state: { fromLogin: true },
          });
        }

        enqueueSnackbar(t("Logged In Successfully"), {
          variant: "success",
          autoHideDuration: 2000,
        });
      }
    });
  };

  //function
  const clearAllState = () => {
    setIs_readyLoader(false);
    setBtnLoader(false);
    localStorage.removeItem("last_visited_dataset");
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("LoggedInUser");
    localStorage.removeItem("last_visited_dataset");
    localforage.clear();
    queryClient.clear();
    removeAllCookies();
    removeCache();
    dispatch(setIsAllHeaderApiFetched(false));
    dispatch(setIsAccountSelectOverlayOpen(null));
    dispatch(setIsFirstLoginOverlayOpen(false));
    dispatch(setLoading(false));
    dispatch(setIsLoading(false));
    dispatch(setIsFatching(false));
    dispatch(setPopupStatus(null));
    dispatch(setPopupStatus2(null));
    dispatch(setPopupStatus3(null));
    dispatch(setPopupStatus4(null));
    dispatch(setTransactionsOverlayStatus(null));
    dispatch(
      authSlice.actions.setAuthTokens({
        token: null,
        refreshToken: null,
      })
    );
  };

  const handleLogin = (email, password) => {
    setBtnLoader(true);
    if (isRememberMe) {
      setEncryptedItem(UserLogin, { email, password }, UserLogin);
    }
    axios
      .post(`${process.env.REACT_APP_API_URL}/auth/login/`, {
        email,
        password,
      })
      .then((res) => {
        localStorage.setItem("token", res?.data.access);
        localStorage.setItem("LoggedInUser", email);
        getProfile(res);
      })
      .catch((err) => {
        setBtnLoader(false);
        if (err.response?.data?.otp_token?.length) {
          setIsOtp(true);
        } else {
          enqueueSnackbar(t("login_credenitals_wrong_msg"), {
            variant: "error",
            autoHideDuration: 4000,
          });
        }
      });
  };

  const onChangeRememberMe = (event, checked) => {
    setIsRememberMe(checked);
  };

  const handleChange = (event) => {
    formik.handleChange(event);
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onClickBack = () => {
    setIsOtp(!isOtp);
  };

  //render function
  function Copyright() {
    return (
      <Typography
        component="div"
        variant="body2"
        color="color.description"
        align="center"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", small: "row" },
          position: "absolute",
          bottom: 16,
          left: "50%",
          width: { xs: "70%", sm: "60%" },
          transform: "translate(-50%, -50%)",
        }}
      >
        <Box>
          <Link color="inherit" href="//finban.io/imprint/">
            {t("Imprint")}
          </Link>{" "}
          <span>&#8226;</span>{" "}
          <Link color="inherit" href="//finban.io/datenschutzerklaerung/">
            {t("Privacy_Policy")}
          </Link>{" "}
          <span>&#8226;</span>{" "}
          <Link color="inherit" href="//finban.io/agbs/">
            {t("Terms_Conditions")}
          </Link>
        </Box>
        <Box>finban.io - {format(new Date(), "yyyy")} </Box>
      </Typography>
    );
  }

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={5.5}
      sx={{ position: "relative" }}
      component={Box}
    >
      {isOtp ? (
        <BackButton
          onClick={onClickBack}
          sx={{ position: "absolute", top: "1rem", left: "1rem" }}
        />
      ) : null}
      <Box
        sx={{
          my: "8rem",
          mx: { xs: 0, sm: "4rem" },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: isOtp ? "center" : "start",
        }}
      >
        <Link
          href="#"
          sx={{
            width: "10rem",
            mr: "2rem",
          }}
        >
          <img
            src={Images.finban_logo_full}
            width={"100%"}
            height={"100%"}
            alt="App_Logo"
          />
        </Link>

        {isOtp ? (
          <OtpVerification
            values={formik.values}
            getProfile={getProfile}
            is_readyLoader={is_readyLoader}
          />
        ) : (
          <Box
            component="form"
            noValidate
            onSubmit={formik.handleSubmit}
            sx={{ mt: "1rem", width: { xs: "70%", sm: "60%" } }}
          >
            <TextField
              size={mdSize ? "small" : "medium"}
              margin="normal"
              required
              fullWidth
              id="email"
              label={t("Email_Address")}
              name="email"
              autoComplete="email"
              autoFocus
              value={formik.values.email}
              onChange={handleChange}
              onBlur={formik.handleBlur}
              sx={inputStyle}
            />
            <Box sx={errorStyle}>
              {formik.errors.email ? <div>{formik.errors.email} </div> : null}
            </Box>
            <TextField
              size={mdSize ? "small" : "medium"}
              margin="normal"
              required
              fullWidth
              name="password"
              label={t("Password")}
              type={values.showPassword ? "text" : "password"}
              id="password"
              autoComplete="current-password"
              value={formik.values.password}
              onChange={handleChange}
              onBlur={formik.handleBlur}
              endadornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              sx={inputStyle}
            />
            <Box sx={errorStyle}>
              {formik.errors.password ? (
                <div>{formik.errors.password} </div>
              ) : null}
            </Box>
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label={t("Remember_me")}
              onChange={onChangeRememberMe}
              checked={isRememberMe}
            />
            <Button
              type="submit"
              fullWidth
              disabled={is_readyLoader}
              variant="contained"
              loading={btnLoader}
              loadingIndicator="Loading…"
              sx={{
                mt: "3rem",
                mb: "2rem",
                fontFamily: Fonts.Text,
                // textTransform: "capitalize",
              }}
            >
              {t("Sign_In")}
            </Button>
            {is_readyLoader && (
              <Box
                sx={{
                  color: Color?.themeColor2,
                  flexDirection: "row",
                  display: "inline-flex",
                  alignItems: "center",
                }}
              >
                <CircularProgress
                  color="inherit"
                  size={23}
                  sx={{
                    color: Color.themeColor2,
                    mr: 1,
                  }}
                />
                <Typography
                  sx={{
                    ml: 1,
                    fontFamily: Fonts.Text,
                    [theme.breakpoints.down("s1536")]: { fontSize: "0.8rem" },
                  }}
                >
                  {t("user_account_ready_status_message")}
                </Typography>
              </Box>
            )}
            <Grid
              container
              sx={{ mt: "1rem", flexDirection: { xs: "column", small: "row" } }}
            >
              <Grid item xs>
                <NavigateLink
                  to="/auth/password_reset/"
                  style={{
                    color: "inherit",
                    fontSize: "1rem",
                  }}
                  replace
                >
                  {t("Forgot_password")}
                </NavigateLink>
              </Grid>
              <Grid item sx={{ display: "flex", alignItems: "center" }}>
                <Link
                  variant="div"
                  component={"div"}
                  sx={{
                    fontSize: "0.95rem",
                    color: theme.palette.primary.main,
                    "&:hover": {
                      color: theme.palette.primary.dark,
                    },
                  }}
                >
                  <NavigateLink
                    to="/register"
                    style={{ color: "inherit" }}
                    replace
                  >
                    {t("not_have_account")}
                  </NavigateLink>
                </Link>
              </Grid>

              {t("maintenance_login_message") !== "hide" ? (
                <Grid item xs={12} sx={{ mt: "2rem" }}>
                  <Alert severity="warning">
                    {t("maintenance_login_message")}
                  </Alert>
                </Grid>
              ) : null}
            </Grid>
          </Box>
        )}
      </Box>
      <Copyright />
    </Grid>
  );
};

const BackButton = ({ onClick, sx }) => {
  return (
    <Button
      onClick={onClick}
      variant="contained"
      disableElevation
      sx={{
        color: Color.tailwind.slate[500],
        backgroundColor: Color.tailwind.slate[100],
        borderRadius: 2,
        width: "3rem",
        height: "3rem",
        boxShadow: "none",
        p: 0,
        minWidth: "auto",
        "&:hover": {
          backgroundColor: Color.tailwind.purple[100],
        },
        ...sx,
      }}
    >
      <ArrowBackIcon />
    </Button>
  );
};
